import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-54d2f6e5"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "caodi"
};
const _hoisted_2 = {
  key: 0,
  class: "card"
};
const _hoisted_3 = {
  class: "img"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "name"
};
const _hoisted_6 = {
  class: "jian"
};
const _hoisted_7 = {
  class: "des"
};
const _hoisted_8 = {
  class: "zeng"
};
const _hoisted_9 = {
  class: "zeng"
};
const _hoisted_10 = {
  key: 0,
  class: "zeng"
};
const _hoisted_11 = {
  class: "shu_title"
};
const _hoisted_12 = {
  class: "shu"
};
const _hoisted_13 = {
  class: "price_title"
};
const _hoisted_14 = {
  class: "price"
};
const _hoisted_15 = {
  key: 1,
  class: "btn"
};
const _hoisted_16 = {
  key: 2,
  class: "btn"
};
const _hoisted_17 = {
  class: "yijie"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_button = _resolveComponent("van-button");
  const _component_Footer = _resolveComponent("Footer");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_nav_bar, {
    title: $data.lang.u1,
    onClickLeft: $options.back,
    onClickRight: $options.onClickLeft
  }, {
    left: _withCtx(() => [_createVNode(_component_van_icon, {
      name: "arrow-left"
    })]),
    right: _withCtx(() => [_createVNode(_component_van_icon, {
      name: "chat-o",
      size: "18"
    })]),
    _: 1
  }, 8, ["title", "onClickLeft", "onClickRight"]), _createElementVNode("div", _hoisted_1, [$data.dao.logo != undefined ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
    src: $data.app_root + $data.dao.logo
  }, null, 8, _hoisted_4)]), _createElementVNode("div", _hoisted_5, _toDisplayString($data.dao.title), 1), _createElementVNode("div", _hoisted_6, _toDisplayString($data.dao.des1), 1), _createElementVNode("div", _hoisted_7, _toDisplayString($data.dao.des), 1), _createElementVNode("div", _hoisted_8, _toDisplayString($data.lang.t1) + " : " + _toDisplayString($data.dao.zeng) + "%", 1), _createElementVNode("div", _hoisted_9, _toDisplayString($data.lang.t8) + " : " + _toDisplayString($data.dao.sort), 1), $data.jie && $data.jie.id > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString($data.lang.t7) + " : " + _toDisplayString($data.jie.ci), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_11, _toDisplayString($data.lang.t3), 1), _createElementVNode("div", _hoisted_12, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dao.shang, (item, index) => {
    return _openBlock(), _createElementBlock("span", {
      style: _normalizeStyle('background-color:' + item.color)
    }, _toDisplayString(item.name), 5);
  }), 256))]), _createElementVNode("div", _hoisted_13, _toDisplayString($data.lang.t4), 1), _createElementVNode("div", _hoisted_14, _toDisplayString($data.dao.price) + "HOC", 1), $data.jie && $data.jie.id == undefined ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createVNode(_component_van_button, {
    round: "",
    block: "",
    type: "primary",
    "native-type": "submit",
    color: "rgb(169 35 220)",
    onClick: _cache[0] || (_cache[0] = $event => $options.to_dao($data.dao.id))
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($data.lang.t2), 1)]),
    _: 1
  })])) : (_openBlock(), _createElementBlock("div", _hoisted_16, [_createVNode(_component_van_button, {
    round: "",
    block: "",
    type: "primary",
    "native-type": "submit",
    color: "#6d6d6d"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($data.lang.t2), 1)]),
    _: 1
  }), _createElementVNode("p", _hoisted_17, _toDisplayString($data.jie.create_time) + _toDisplayString($data.lang.t5), 1)]))])) : _createCommentVNode("", true), _createVNode(_component_Footer, {
    lang_list: $data.lang_list,
    lang: $data.lang
  }, null, 8, ["lang_list", "lang"])])]);
}